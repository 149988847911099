<template>
  <a class="footer"  href="https://beian.miit.gov.cn/" target="_blank">Copyright © 山西海云启辰科技有限公司 版权所有 晋ICP备2022001434号-1</a>
</template>

<script>
export default {
  name: 'FooterModule'
}
</script>

<style lang="less" scoped>
.footer {
  color: @gray3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 35px;
  line-height: 18px;
  padding-top: 15px;
  cursor: pointer;
}
</style>

