import Vue from 'vue'
import App from './App.vue'
import ElementUI, { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/fonts/fonts.css'
import dayjs from 'dayjs';
import router from './router'
import '../theme/index.css'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.dayjs = dayjs
Vue.prototype.$message = Message

// 全局路由拦截器
router.beforeEach((to, from,next) => {
  console.log('全局路由拦截器-------',to,from);
  // 获取用户登录凭证
  let token = sessionStorage.getItem('')
  // 定义需要权限的路由
  let authorityRoute = []
  // 条件判断是否放行页面
  // ......
  next()
})


new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
