import Vue from 'vue'
import VRouter  from 'vue-router'
// import Index from '../pages/index/index'
// import About from '../pages/about/index'
// import Signing from '../pages/signing/index'
// import Recommend from '../pages/recommend/index'
// import Notice from '../pages/notice/index'
// import Buy from '../pages/buy/index'
// import Mine from '../pages/mine/index'
// import Account from '../pages/mine/account'
// import YunCang from '../pages/mine/yunCang'
// import Integral from '../pages/mine/integral'
// import RegardPage from '../pages/mine/regard'
// import Map from "../pages/map/index"
// import Supervise from "../pages/supervise/index"
// import Logistics from "../pages/logistics/index"
// import Sale from "../pages/sale/index"
// import Manage from "../pages/manage/index"
// import Finance from "../pages/finance/index"
// import BuySuccess from "../pages/buySuccess/index"
// import Login from "../pages/login/index"
// import Message from "../pages/message/index"
// import Release from "../pages/release/index"
// import Help from "../pages/help/index"
// import DigitalEnterprise from "../pages/digitalEnterprise/index"
// 路由全量加载改为懒加载。。。
const Home = () => import('../pages/index/index')
const About = () => import('../pages/about/index')
const Signing = () => import('../pages/signing/index')
const Recommend = () => import('../pages/recommend/index')
const Notice = () => import('../pages/notice/index')
const Buy = () => import('../pages/buy/index')
const Mine = () => import('../pages/mine/index')
const Account = () => import('../pages/mine/account')
const YunCang = () => import('../pages/mine/yunCang')
const Integral = () => import('../pages/mine/integral')
const RegardPage = () => import('../pages/mine/regard')
const EnterpriseInfo = () => import('../pages/mine/enterpriseInfo')
const Map = () => import('../pages/map/index')
const Supervise = () => import('../pages/supervise/index')
const Logistics = () => import('../pages/logistics/index')
const Sale = () => import('../pages/sale/index')
const Manage = () => import('../pages/manage/index')
const Finance = () => import('../pages/finance/index')
const BuySuccess = () => import('../pages/buySuccess/index')
const Login = () => import('../pages/login/index')
const Message = () => import('../pages/message/index')
const Release = () => import('../pages/release/index')
const Help = () => import('../pages/help/index')
const DigitalEnterprise = () => import('../pages/digitalEnterprise/index')
const PurChase = () => import('../pages/purChase/purIndex')
const Lobby = () => import('../pages/lobby/index')



Vue.use(VRouter)

export default new VRouter({
  routes: [
    {
      path: '/',
      name: 'index',
      component: Home
    },
    {
      path: '/about/:detailsId?',
      name: 'about',
      component: About
    },
    {
      path: '/signing/:id?/:enterpriseName?/:img',
      name: 'signing',
      component: Signing
    },
    {
      path: '/recommend/:searchStr?/:threeProId?',
      name: 'recommend',
      component: Recommend
    },
    {
      path: '/lobby/:searchStr?/:threeProId?',
      name: 'lobby',
      component: Lobby
    },
    {
      path: '/notice',
      name: 'notice',
      component: Notice
    },
    {
      path: '/buy',
      name: 'buy',
      component: Buy
    },
    {
      path: '/map',
      name: 'map',
      component: Map
    },
    {
      path: '/supervise',
      name: 'supervise',
      component: Supervise
    },
    {
      path: '/logistics',
      name: 'logistics',
      component: Logistics
    },
    {
      path: '/sale',
      name: 'sale',
      component: Sale
    },
    {
      path: '/manage',
      name: 'manage',
      component: Manage
    },
    {
      path: '/finance',
      name: 'finance',
      component: Finance
    },
    {
      path: '/buySuccess',
      name: 'buySuccess',
      component: BuySuccess
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/message',
      name: 'message',
      component: Message
    },
    {
      path: '/release/:data?',
      name: 'release',
      component: Release
    },
    {
      path: '/help',
      name: 'help',
      component: Help
    },
    {
      path: '/digitalEnterprise',
      name: 'digitalEnterprise',
      component: DigitalEnterprise
    },
    {
      path: '/purChase',
      name: 'purChase',
      component: PurChase
    },

    {
      path: '/mine/',
      name: 'mine',
      component: Mine,
      children:[
        {
          path: '',
          component: Account,
        },
        {
          path: 'yunchang',
          component: YunCang,
        },
        {
          path: 'integral',
          component: Integral,
        },
        {
          path: 'regard',
          component: RegardPage,
        },
        {
          path: 'enterpriseInfo',
          component: EnterpriseInfo,
        }
      ]
    }

  ]
})

