<template>
  <div id="app">
    <Header v-if="headerType" :key="$router.currentRoute.path"></Header>
    <div class="content">
      <router-view></router-view>
    </div>
    <Footer v-if="footerType"></Footer>
  </div>
</template>

<script>
import Header from './components/header.vue'
import Footer from './components/footer.vue'
import 'element-ui/lib/theme-chalk/display.css'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      headerType: true,
      footerType: true
    }
  },
  watch:{
      $route(to,from){
        this.getActivePath()
      }
  },
  created() {
    this.getActivePath()
  },
  methods: {
    backToTop() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 0) {
        window.requestAnimationFrame(this.backToTop);
        window.scrollTo(0, scrollTop - scrollTop / 8);
      }
    },
    getActivePath() {
      this.backToTop()
      if (this.$router.currentRoute.path === '/login') {
        this.headerType = false
      } else {
        this.headerType = true
      }
      if (this.$router.currentRoute.path === '/about') {
        this.footerType = false
      }else{
        this.footerType = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
// * {
//   margin: 0;
//   padding: 0;
// }

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: @cn, @en;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  margin-top: 60px;
  background: #F5F5F5;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .content {
    min-height: calc(100vh - 104px);
  }

  /deep/.content_pc {
    width: 1204px;
  }

  /deep/.el-button:hover {
    border: 1px solid #E2231A;
  }
}
</style>
