<template>
  <el-row>
    <el-col :lg="24" class="headerBox">
      <div class="hidden-md-and-down header">
        <div class="left">
          <div class="welcoming">欢迎来到熊猫煤仓</div>
          <div class="loginType" v-if="loginType">
            <div class="phone">{{ loginType }}</div>
            <div class="logout" @click="outLogin()">退出</div>
          </div>
          <div class="loginType" v-else @click="toLogin()">
            请<span>登陆</span>
            <div class="stick"></div><span>注册</span>
          </div>
        </div>
        <div class="right">
          <div class="list" @click="messageClick()">消息</div>
          <el-popover placement="bottom" trigger="hover" popper-class="popoverBox">
            <div class="list" slot="reference">公众号</div>
            <slot><el-image :src="require('../assets/公众号.jpg')" class="popoverImg"></el-image></slot>
          </el-popover>
          <el-popover placement="bottom" trigger="hover" popper-class="popoverBox">
            <div class="list" slot="reference">客服</div>
            <slot><el-image :src="require('../assets/客服.jpg')" class="popoverImg"></el-image></slot>
          </el-popover>
          <el-popover placement="bottom" trigger="hover" popper-class="popoverBox">
            <div class="list" slot="reference">小程序</div>
            <slot><el-image :src="require('../assets/weChat.png')" class="popoverImg"></el-image></slot>
          </el-popover>
          <el-popover placement="bottom" trigger="hover" popper-class="popoverBox">
            <div class="list" slot="reference">app</div>
            <!-- <slot><el-image :src="require('../assets/app.jpg')" class="popoverImg"></el-image></slot> -->
            <slot>
              <div class="labelBox">
                <div>
                  <div class="appLabel">iOS</div>
                  <el-image :src="require('../assets/app_ios.png')" class="popoverImg"></el-image>
                </div>
                <div>
                  <div class="appLabel">Android</div>
                  <el-image :src="require('../assets/app_android.png')" class="popoverImg"></el-image>
                </div>
              </div>
            </slot>
          </el-popover>
        </div>
      </div>
    </el-col>

  </el-row>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css';
export default {
  name: 'HeaderModule',
  data() {
    return {
      loginType: '',
      currentPage:'',
    }
  },
  created() {
    let userData = sessionStorage.getItem('userData')
    this.currentPage = this.$route.path
    if (userData) {
      this.loginType = JSON.parse(userData).phone
    }
  },
  methods: {
    toIndex() {
      this.$router.push({ path: '/' })
    },
    toLogin() {
      this.$router.push({ path: '/login' })
    },
    outLogin() {
      sessionStorage.removeItem('Authorization')
      sessionStorage.removeItem('userData')
      if(this.currentPage == '/'){
        this.$router.go(0)
      } else {
        this.$router.push({ path: '/' })
      }
    },
    messageClick() {
      if (this.loginType) {
        this.$router.push({ path: '/message' })
        // this.$router.push({path:'/supervise'})
      } else {
        this.$router.push({ path: '/login' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.headerBox {
  display: flex;
  justify-content: center;
  width: calc(100vw - 60px);
  background: #F2F2F2;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 36px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: @gray1;

    .left {
      display: flex;

      .welcoming {
        margin-right: 24px;
      }

      .loginType {
        display: flex;
        align-items: center;
        // font-size: 16px;

        .stick {
          width: 1px;
          height: 18px;
          background: @gray3;
        }

        >span {
          margin: 0 4px;
          cursor: pointer;
        }

        .logout {
          margin-left: 24px;
          color: @red1;
          cursor: pointer;
        }
      }
    }

    .right {
      display: flex;
      width: 296px;
      justify-content: space-between;

      .list {
        cursor: pointer;
      }
    }
  }

  .index_top {
    width: 70%;
    display: flex;
    padding: 29px 0 0px 0;

    .logo {
      width: 146px;
      height: 40px;
      background: url("../assets/logo.png");
      margin-right: 42px;
    }

    .queryBox {
      display: flex;
      border: 2px solid #E2231A;
      height: 36px;
      margin-right: 8px;

      .query_input {
        width: 500px;
        height: 36px;
        line-height: 36px;

        /deep/.el-input__inner {
          height: 36px;
          line-height: 36px;
          border: 0;
          border-radius: 0;
          display: flex;
        }
      }

      .query_btn {
        width: 100px;
        height: 36px;
        line-height: 36px;
        padding: 0;
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        background: #E2231A;
        border-radius: 0;
        border: 0;
      }
    }

    .publish {
      padding: 0;
      width: 100px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #E2231A;
      color: #E2231A;
      font-size: 14px;
      margin-right: 88px;
    }

    .telephone {
      text-align: left;

      .telephoneName {
        color: @gray2;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .telephoneNum {
        background: var(--linear, linear-gradient(90deg, #E2231A 0%, #CF2C2C 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}

.labelBox{
  display: flex;
}

.appLabel{
  text-align: center;
}
</style>